<script setup>
import { ref, computed, onMounted, nextTick } from "vue";
import {checkNeedShowMore, checkCommission} from '../helper/AdvertiserHelper'

const props = defineProps({
    data: Object,
});
const showMore = ref(true)
let needsShowMore = ref(true)
const commissions = ref({
  cpc: null,
  cpl: null,
  cpo: null
})
const descriptionElement = ref(null);

function toggleShowMore() {
  showMore.value = !showMore.value
}


onMounted(() => {
  commissions.value.cpc = checkCommission(props.data.commissions, 'click', null);
  commissions.value.cpl = checkCommission(props.data.commissions, 'lead_fixed', 'lead_percent');
  commissions.value.cpo = checkCommission(props.data.commissions, 'sale_fixed', 'sale_percent');
  checkNeedShowMore(descriptionElement, showMore, needsShowMore);
});

</script>

<template>
    <div :id="`campaign-${data.campaign_id}`" :class="{'columns campaign-teaser':true, 'is-active': showMore}">
        <div class="column is-3 is-background-dark">
          <div class="commission-container is-flex is-align-items-center is-color-white">
            <div v-if="commissions.cpc" class="campaign-teaser-commision is-bold">CPC: <span class="heading-4">{{ commissions.cpc }}</span></div>
            <div v-if="commissions.cpl" class="campaign-teaser-commision is-bold">CPL: <span class="heading-4">{{ commissions.cpl }}</span></div>
            <div v-if="commissions.cpo" class="campaign-teaser-commision is-bold">CPO: <span class="heading-4">{{ commissions.cpo }}</span></div>

          </div>
        </div>
        <div class="column is-9 is-background-white p-4-mobile">
            <div class="tag-row">
                <span
                    v-for="tag in data.campaign_tags"
                    :key="tag"
                    :class="{
                        'tag': true,
                    }"
                >
                    {{ tag }}
                </span>
               <span class="tag-row ml-auto">
                <span
                    v-for="tag in data.campaign_countries"
                    :key="tag"
                    :class="{
                        'tag': true,
                    }"
                >
                    {{ tag }}
                </span>
              </span>
            </div>
            <div class="heading-3 mt-2">{{ data.campaign_name }}</div>
            <div class="advertiser-teaser-description content mt-2" ref="descriptionElement" v-html="data.campaign_description">
           
            </div>
            <div class="mt-2">
              <a class="is-bold" v-if="needsShowMore" @click="toggleShowMore()">{{showMore ? 'Weniger anzeigen' : 'Mehr anzeigen'}}</a>
            </div>
        </div>
    </div>
</template>
