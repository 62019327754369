class CustomEvents {
    constructor() {
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        this.btnToggleTargetVisibilityEvt();
        this.scrollToByJumpToElement();
    }

    btnToggleTargetVisibilityEvt() {
        document.addEventListener('DOMContentLoaded', () => {
            let evtElements = document.querySelectorAll('.js-evt-toggle');

            if (evtElements.length > 0) {
                for (let item of evtElements) {
                    const hasToBeOpen = item.getAttribute('data-open-item');
                    const hasToBeClosed = item.getAttribute('data-close-item');

                    item.addEventListener('click', () => {
                        let itemOpen = document.querySelector('.' + hasToBeOpen);
                        let itemClose = document.querySelector('.' + hasToBeClosed);

                        itemOpen.classList.remove('is-hidden');
                        itemClose.classList.add('is-hidden');
                    });
                }
            }
        });
    }

    scrollToByJumpToElement() {
        document.addEventListener('DOMContentLoaded', () => {
            let jumpsTo = document.querySelector('.js-evt-jump-to');

            if(jumpsTo !== null && jumpsTo.value.length > 0) {
                this.smoothScroll(jumpsTo.value);
            }
        });
    }

    smoothScroll(selector) {
        document.querySelector(selector).scrollIntoView({
            behavior: 'smooth'
        });
    }
}

export default new CustomEvents();
