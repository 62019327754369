<script setup>
import { ref, computed, onMounted, nextTick } from "vue";
import {checkCommission} from '../helper/AdvertiserHelper'

const props = defineProps({
    campaigns: Object,
});

</script>

<template>
  <table class="campaign-table">
    <tr>
      <th>Kampagnen</th>
      <th class="commission-table-column has-text-centered">CPC</th>
      <th class="commission-table-column has-text-centered">CPL</th>
      <th class="commission-table-column has-text-centered">CPO</th>
    </tr>
    <tr v-for="campaign of campaigns">
      <td><a class="is-no-link" :href="`#campaign-${campaign.campaign_id }`">{{campaign.campaign_name}}</a></td>
      <td class="commission-table-column has-text-centered">{{ checkCommission(campaign.commissions, 'click', null, true)}}</td>
      <td class="commission-table-column has-text-centered">{{ checkCommission(campaign.commissions, 'lead_fixed', 'lead_percent', true) }}</td>
      <td class="commission-table-column has-text-centered">{{ checkCommission(campaign.commissions, 'sale_fixed', 'sale_percent', true) }}</td>
    </tr>
  </table>
</template>
