class Form {
    constructor() {
        this.form = document.querySelector('.formbuilder');
        this.tmpFilesSizePool = [];
        this.maxFileSize = 20000000;
    }

    init() {
        if (this.form !== null) {
            this.inputFileEvts();
            this.formSubmit();
        }
    }

    formSubmit() {
        if (this.form !== null) {
            this.form.addEventListener('submit', () => {
                let submitBtn = this.form.querySelector('button[type="submit"]');

                if ('actionEventText' in submitBtn.dataset) {
                    submitBtn.textContent = submitBtn.dataset.actionEventText;
                    submitBtn.disabled = true;
                }
            });
        }
    }

    inputFileEvts() {
        document.addEventListener('DOMContentLoaded', () => {
            // 1. Display file name when select file
            let fileInputs = document.querySelectorAll('.file.has-name');
            let tooBigMessageItem = document.querySelector('.upload-files-too-big');
            let submitBtn = this.form.querySelector('button[type="submit"]');

            for (let fileInput of fileInputs) {
                let input = fileInput.querySelector('.file-input');
                let name = fileInput.querySelector('.file-name');

                input.addEventListener('change', () => {
                    let files = input.files;
                    this.tmpFilesSizePool[input.name] = 0;
                    submitBtn.disabled = false;

                    if (files.length === 0) {
                        name.innerHTML = 'Keine Datei vorhanden.';
                    } else if (files.length >= 1) {
                        // name.innerText = files[0].name
                        let filesNames = [];

                        for (let file of files) {
                            filesNames.push(file.name);
                            this.tmpFilesSizePool[input.name] += file.size;
                        }

                        name.innerHTML = filesNames.join('<br>');
                    }

                    const totalFilesSize = Object
                        .values(this.tmpFilesSizePool)
                        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                    if (totalFilesSize > this.maxFileSize) {
                        submitBtn.disabled = true;
                        tooBigMessageItem.classList.remove('is-hidden');
                    } else {
                        tooBigMessageItem.classList.add('is-hidden');
                    }
                });
            }

            // 2. Remove file name when form reset
            let forms = document.getElementsByTagName('form');
            for (let form of forms) {
                form.addEventListener('reset', () => {
                    let names = form.querySelectorAll('.file-name');
                    for (let name of names) {
                        name.innerHTML = 'Keine Datei vorhanden.';
                    }
                });
            }
        });
    }
}

export default new Form();
