import {createApp} from '../../node_modules/vue/dist/vue.esm-bundler';

//components
import Advertisers from './components/Advertisers.vue';
import CampaignTable from './components/CampaignTable.vue';


//modules
import collapsible from './modules/Collapsible';
import _switch from './modules/Switch';
import animate from './modules/Animate';
import navbar from './modules/Navbar';
import slider from './modules/Slider';
import modal from './modules/Modal';
import form from './modules/Form';
import customEvents from './modules/CustomEvents';
import Box from './modules/Box'

import scrollytellingBars from './scrollytelling/ScrollytellingBars';
import scrollytellingCta from './scrollytelling/ScrollytellingCta';
import scrollytellingToggle from './scrollytelling/ScrollytellingToggle';
import parallax from './modules/Parallax'


collapsible.init();
_switch.init();
animate.init();
navbar.init();
slider.init();
modal.init();
form.init();
customEvents.init();

//init parallax Header
parallax.initHeader('.hero', '.hero .background-img img', '.hero .foreground-js');

//init feature boxes
const featureBoxes = document.querySelectorAll('.js-feature-box')
if (featureBoxes.length) {
  featureBoxes.forEach(box => {
    let siblingIds = [];
    if (box.dataset.siblingIds) {
      siblingIds = box.dataset.siblingIds.split(',');
    }
   const boxInstance =  new Box(box, siblingIds)
   boxInstance.initFeatureBox()
  })
}


// init scrollytelling elements
scrollytellingBars.init();
scrollytellingCta.init();
scrollytellingToggle.init();

//Check Browser
function checkBrowser() {
  var userAgent = navigator.userAgent;
  // Prüfen, ob der Browser Safari ist
  var isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
  if (isSafari) {
      // Füge die Klasse 'safari' zum 'body' hinzu, wenn der Browser Safari ist
      document.body.classList.add('safari');
  }
}
checkBrowser();

const advertiserContainer = document.querySelector('.vue-advertisers');
if (advertiserContainer) {
    const advertisersApp = createApp({});
    advertisersApp.component('advertisers', Advertisers);
    advertisersApp.mount('.vue-advertisers');
}
const campaignTableContainer = document.querySelector('.vue-campaign-table');
if (campaignTableContainer) {
    const campaignTableApp = createApp({});
    campaignTableApp.component('campaign-table', CampaignTable);
    campaignTableApp.mount('.vue-campaign-table');
}



//sticky eyebrow text in cases
const stickySection = document.querySelector('.js-sticky-section');
const stickyContent = document.querySelector('.js-sticky-content');

const options = {
  root: null,
  rootMargin: '-70px 0px 0px 0px',
  threshold: 1
};

// Speichert die vorherigen Werte für jedes Element
let elementsState = {};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    const elementId = entry.target.id;

    // Initialisiere den Zustand 
    if (!elementsState[elementId]) {
      elementsState[elementId] = { previousY: 0, previousRatio: 0 };
    }

    const currentY = entry.boundingClientRect.y;
    const currentRatio = entry.intersectionRatio;
    const isIntersecting = entry.isIntersecting;

    //scrolling down
    if (currentY < elementsState[elementId].previousY) {
      if (currentRatio > elementsState[elementId].previousRatio && isIntersecting) { 
        /* console.log("Scrolling down enter"); */
      } else {
        stickyContent.textContent = entry.target.innerText;
        stickySection.classList.add('is-active');
        /* console.log("Scrolling down leave"); */
      }
    }
    //scrolling up
    else if (currentY > elementsState[elementId].previousY && isIntersecting) {
      if (currentRatio < elementsState[elementId].previousRatio) {
        /* console.log("Scrolling up leave"); */
      } else {
        let curIndex = eyebrows.indexOf(entry.target);
        const prevIndex = curIndex - 1;
        if (curIndex == 0) {
          stickySection.classList.remove('is-active');
        } else {
          stickyContent.textContent = eyebrows[prevIndex].innerText;
          stickySection.classList.add('is-active');
        }
        /* console.log("Scrolling up enter"); */
      }
    }

    // Aktualisiere den Zustand 
    elementsState[elementId].previousY = currentY;
    elementsState[elementId].previousRatio = currentRatio;
  });
}, options);

const eyebrows = Array.from(document.querySelectorAll('.js-article-content .js-eyebrow'));


if (eyebrows.length && stickyContent) {
  eyebrows.forEach(eyebrow => {
    // giving elements an id
    if (!eyebrow.id) {
      eyebrow.id = 'unique-id-' + Math.random().toString(36).substr(2, 9);
    }
    observer.observe(eyebrow);
  });
}

// loading circle for all picture elements inside .loader-js parent
const loaders = document.querySelectorAll('.loader-js');

if (loaders.length) {
  loaders.forEach(loader => {
    const pictures = loader.querySelectorAll('picture');
    pictures.forEach(picture => {
      let img = picture.querySelector('img');
      if (!img.complete) {
        picture.classList.add('is-element-loading');
      }
      img.onload = () => {
        picture.classList.remove('is-element-loading');
      };
    });
  });
}

