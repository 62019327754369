class Switch {
    constructor() {
        this.switches = document.querySelectorAll('.js-switch');
        this.switchContent = document.querySelectorAll('.js-switch-content');
        this.images = document.querySelectorAll('.js-location-image');
    }

    init() {
        if (this.switches.length > 0) {
            this.runSwitch();
        }
    }

    runSwitch() {
        // places click event on each switch
        this.switches.forEach((_switch) => {
            //when clicked
            _switch.addEventListener('click', (e) => {
                //take switch id (target)
                const target = e.target.dataset.target;
                // loop throgh switches and style active accordingly
                this.switches.forEach((_switch) => {
                    if (target === _switch.dataset.target) {
                        _switch.classList.add('is-active');
                    } else {
                        _switch.classList.remove('is-active');
                    }
                });

                //loop through content and set only active display: block
                //other display: none
                this.switchContent.forEach((content) => {
                    let contentId = content.dataset.id;
                    if (target === contentId) {
                        content.classList.add('is-active');
                    } else {
                        content.classList.remove('is-active');
                    }
                });
                //for location area only
                //loop through images and set only matching image to visible
                if (this.images.length > 0) {
                    this.images.forEach((image) => {
                        let imageId = image.dataset.id;
                        if (target === imageId) {
                            image.classList.add('is-active');
                        } else {
                            image.classList.remove('is-active');
                        }
                    });
                }
            });
        });
    }
}

export default new Switch();
