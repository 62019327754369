export default class Box {
    constructor(box, siblingIds) {
        this.box = box;
        this.siblingIds = siblingIds
        this.siblingBoxes = null
        this.showMoreState = true;
        this.showMoreButton = box.querySelector(".js-show-more");
        this.showLessButton = box.querySelector(".js-show-less");
    }
    initFeatureBox() {
        if (this.showMoreButton && this.showLessButton) {
            if (this.siblingIds.length) {
              this.siblingBoxes = this.siblingIds.map(id => {
                return document.querySelector(`#${id}`)
               });
            }
            this.liAmount = this.box.dataset.showLi;
            this.lis = this.box.querySelectorAll(`ul li`);
            this.showMoreButton.addEventListener("click", () => {
                this.showLis();
                if(this.siblingBoxes.length) {
                  this.showSiblingLis()
                }
            });
            this.showLessButton.addEventListener("click", () => {
                this.hideLis();
                if(this.siblingBoxes.length) {
                  this.hideSiblingLis()
                }
            });
            this.hideLis();
        }
    }

    hideLis() {
        this.lis.forEach((li, i) => {
            if (i >= this.liAmount) {
                li.classList.add("is-hidden");
            }
        });
        this.showLessButton.classList.add("is-hidden");
        this.showMoreButton.classList.remove("is-hidden");
    }

    hideSiblingLis() {
        if (this.siblingBoxes.length) {
          this.siblingBoxes.forEach(siblingBox => {
            if (!siblingBox) return
            const lis = siblingBox.querySelectorAll(`ul li`);
            const liAmount = siblingBox.dataset.showLi;
            const showMoreButton = siblingBox.querySelector(".js-show-more");
            const showLessButton = siblingBox.querySelector(".js-show-less");
            lis.forEach((li, i) => {
                if (i >= liAmount) {
                    li.classList.add("is-hidden");
                }
            });
            showLessButton.classList.add("is-hidden");
            showMoreButton.classList.remove("is-hidden");
          })
            
        }
    }
    

    showLis() {
        
        this.lis.forEach((li) => {
            li.classList.remove("is-hidden");
        });
        this.showMoreButton.classList.add("is-hidden");
        this.showLessButton.classList.remove("is-hidden");
    }

    showSiblingLis() {
      if (this.siblingBoxes.length) {
        this.siblingBoxes.forEach(siblingBox => {
          if (!siblingBox) return
          const lis = siblingBox.querySelectorAll(`ul li`);
          const showMoreButton = siblingBox.querySelector(".js-show-more");
          const showLessButton = siblingBox.querySelector(".js-show-less");
          lis.forEach((li) => {
                  li.classList.remove("is-hidden");
          });
          showMoreButton.classList.add("is-hidden");
          showLessButton.classList.remove("is-hidden");
        })
          
      }
  }

}
