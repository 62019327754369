import {gsap} from "gsap";

class ScrollytellingToggle {
    constructor() {
        this.container = document.querySelectorAll(".js-scrollytelling-toggle");
    }

    init() {
        if (this.container.length > 0) {
            this.listen();
        }
    }

    listen() {
        // Fullscreen-Background will be reached after 50% of bar-container-height
        const durationFactor = 0.5;

        this.container.forEach((container) => {

            let bars = [];
            bars.push(container.querySelector('#OFFbar1 path'));
            bars.push(container.querySelector('#OFFbar2 path'));
            bars.push(container.querySelector('#OFFbar3 path'));

            let switches = [];
            switches.push(container.querySelector('#OFFslider1 circle'));
            switches.push(container.querySelector('#OFFslider2 circle'));
            switches.push(container.querySelector('#OFFslider3 circle'));

            let backgroundCircles = [];
            backgroundCircles.push(container.querySelector('#OFFcircle3 circle'));
            backgroundCircles.push(container.querySelector('#OFFcircle2 circle'));
            backgroundCircles.push(container.querySelector('#OFFcircle1 circle'));

            let text = container.querySelector('#ONtext');
            let logoCircle = container.querySelector('#ONcircle4');
            let logoBackgroundCircle = container.querySelector('#OFFcircle4 circle');

            const tl = gsap.timeline({paused: true});

            //responsive
            let mm = gsap.matchMedia();

            const content = container.querySelectorAll('.scrollytelling-toggle-content > div');

            mm.add("(max-width: 768px)", () => {
                content.forEach((element, index) => {
                    tl.to(element, {
                        opacity: 0,
                        scrollTrigger: {
                            trigger: element,
                            start: "15vh",
                            end: "20vh",
                            scrub: true,
                            markers: false
                        }
                    });
                });
            });

            tl.to(switches[0], {
                duration: 0.33,
                cy: 727,
                ease: "Expo.easeOut"
            });

            tl.to(bars[0], {
                duration: 0.33,
                fill: '#FF4B41',
                ease: "Expo.easeOut"
            }, 0);

            tl.to(switches[1], {
                duration: 0.33,
                cx: 726,
                ease: "Expo.easeIn"
            }, 0);

            tl.to(bars[1], {
                duration: 0.33,
                fill: '#FFBE23',
                ease: "Expo.easeIn"
            }, 0);

            tl.to(switches[2], {
                duration: 0.33,
                cy: 201,
                ease: "Expo.easeInOut"
            }, 0);

            tl.to(bars[2], {
                duration: 0.33,
                fill: '#0091FF',
                ease: "Expo.easeInOut"
            }, 0);

            tl.to(text, {
                duration: 0.5,
                opacity: 1,
                ease: "Expo.easeOut"
            }, 1);

            tl.to(logoCircle, {
                duration: 0.5,
                opacity: 1,
                ease: "Expo.easeInOut"
            }, 1);

            tl.to(logoBackgroundCircle, {
                duration: 0.5,
                opacity: 0,
                ease: "Expo.easeOut"
            }, 1);

            tl.to(backgroundCircles[0], {
                duration: 0.65,
                fill: '#46DCA5',
                ease: "Expo.easeInOut"
            }, 2);

            tl.to(backgroundCircles[1], {
                duration: 0.65,
                fill: '#46DCA5',
                ease: "Expo.easeInOut"
            }, 2);

            tl.to(backgroundCircles[2], {
                duration: 0.65,
                fill: '#46DCA5',
                ease: "Expo.easeInOut"
            }, 2);


            const scrollAreaHeight = container.offsetHeight;
            let scrollAreaPosition = container.getBoundingClientRect().y * -1;

            window.addEventListener(
                "scroll",
                () => {
                    scrollAreaPosition = container.getBoundingClientRect().y * -1;
                    tl.progress(scrollAreaPosition / (scrollAreaHeight * 0.5));
                },
                false
            );
        });
    }
}

export default new ScrollytellingToggle;
