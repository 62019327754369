// add a data-animation property with the animation class as value, on element you want to animate,
// add animation css class from animation.scss
// add js-animation as class to be observed

class Animate {
    constructor() {
        this.isAnimated = document.querySelectorAll('.js-animation');
        this.hasParallax = document.querySelectorAll('.js-parallax');
    }

    init() {
        if (this.isAnimated.length > 0) {
            this.handleAnimation();
        }
        if (this.hasParallax.length > 0) {
            this.handleParallax();
        }
    }

    handleAnimation() {
        const options = {
            threshold: 0,
        };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const animation = entry.target.dataset.animation;
                    if (animation) {
                        entry.target.classList.add(animation);
                    }
                    observer.unobserve(entry.target);
                }
            });
        }, options);
        this.isAnimated.forEach((animatedElement) => {
            observer.observe(animatedElement);
        });
    }

    // add class js-parallax for initializing element as parallax element
    // add backgroundimage with is-parallax-X (X = integer)
    handleParallax() {
        var sections = Array.from(
            document.querySelectorAll('.js-parallax')
        ).map(function (section) {
            return {
                element: section,
                isIntersecting: false,
            };
        });

        var observer = new IntersectionObserver(
            function (entries) {
                entries.forEach(function (entry) {
                    var section = sections.find(function (s) {
                        return s.element === entry.target;
                    });
                    section.isIntersecting = entry.isIntersecting;
                });
            },
            { threshold: 0.1 }
        );

        sections.forEach(function (section) {
            observer.observe(section.element);
        });

        function parallaxScroll() {
            sections.forEach(function (section) {
                if (section.isIntersecting) {
                    var speed =
                        section.element.getAttribute('data-speed') || 0.5;
                    var rect = section.element.getBoundingClientRect();
                    var offset = rect.top * speed;
                    section.element.style.backgroundPosition =
                        'center ' + -offset + 'px';
                }
            });
            requestAnimationFrame(parallaxScroll);
        }

        requestAnimationFrame(parallaxScroll);
    }
}

export default new Animate();
