//add .js-collapsible to collapsible container
//add .js-is-clickable-collapsible to not collapsing clickable part
//add .js-is-collapsing-collapsible to not klickable, collapsing part
//connect clickable and collapsing part with id
//clickable part gets data-target='id'
//collapsible part gets data-id='id'

class Collapsible {
    constructor() {
        this.Collapsibles = document.querySelectorAll('.js-collapsible');
    }

    init() {
        if (this.Collapsibles.length > 0) {
            this.checkCollapsible();
        }
    }

    checkCollapsible() {
        this.Collapsibles.forEach((collapsible) => {
            let head = collapsible.querySelector(
                '.js-is-clickable-collapsible'
            );
            head.addEventListener('click', () => {
                const target = head.dataset.target;
                const collapsingCollapsibles = document.querySelectorAll(
                    '.js-is-collapsing-collapsible'
                );

                head.classList.toggle('is-active');

                collapsingCollapsibles.forEach((item) => {
                    if (item.dataset.id === target) {
                        item.classList.toggle('is-collapsed');
                    }
                });
            });
        });
    }
}

export default new Collapsible();
