import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MotionPathPlugin from "gsap/MotionPathPlugin";

class ScrollytellingCta {
    constructor() {
        this.container = document.querySelectorAll(".js-scrollytelling-cta");
        gsap.registerPlugin(ScrollTrigger);
        gsap.registerPlugin(MotionPathPlugin);
    }

    init() {
        if (this.container.length > 0) {
            this.listen();
        }
    }

    listen() {
        // Fullscreen-Background will be reached after 50% of bar-container-height
        const durationFactor = 0.5;

        this.container.forEach((container) => {
            let avatarsStopped = false;
            const avatars = [];
            avatars.push(container.querySelector('#User_Blue'));
            avatars.push(container.querySelector('#User_Purple'));
            avatars.push(container.querySelector('#User_Pink'));

            const stars = [];
            stars.push(container.querySelector('#Stars path:nth-child(1)'));
            stars.push(container.querySelector('#Stars path:nth-child(2)'));
            stars.push(container.querySelector('#Stars path:nth-child(3)'));

            const lines = [];
            lines.push(container.querySelector('#User_Blue_x5F_Pfad path'));
            lines.push(container.querySelector('#User_Purple_x5F_Pfad path'));
            lines.push(container.querySelector('#User_Pink_x5F_Pfad path'));

            const path = container.querySelector('#Pfad_16245');

            const cta = container.querySelector('#Call_to_Action_x5F_small');

            const content = container.querySelectorAll('.scrollytelling-cta-content > div');

            // init timeline
            const tlAvatars = gsap.timeline({paused: false});
            const tl = gsap.timeline({paused: true});
            //responsive
            let mm = gsap.matchMedia();

            avatars.forEach((avatar, index) => {
                tlAvatars.to(avatar, {
                    duration: 8,
                    transition: 'none',
                    motionPath: {
                        path: lines[index],
                        align: lines[index],
                        alignOrigin: [0.5, 0.5],
                        autoRotate: false
                    },
                    immediateRender: true,
                    ease: "Expo.linear",
                    repeat: -1,
                    yoyo: true
                }, 0)
            });

            mm.add("(max-width: 768px)", () => {
                content.forEach((element, index) => {
                    tl.to(element, {
                        opacity: 0,
                        scrollTrigger: {
                            trigger: element,
                            start: "-250vh",
                            end: "-200vh",
                            scrub: true,
                            markers: false
                        }
                    });
                });
            });

            tl.to(path, {
                duration: 0.75,
                opacity: 0,
                ease: "Expo.easeIn",
            }, 1);

            tl.to(cta, {
                duration: 0.125,
                translateX: '-=160px',
                translateY: '-=160px',
                transformOrigin: 'center center',
                ease: "Expo.easeInOut",
                onComplete: () => {
                    avatars.forEach((avatar) => {
                        avatar.clientRect = avatar.getBoundingClientRect();
                    });
                    cta.clientRect = cta.getBoundingClientRect();
                    tlAvatars.pause();
                    tl.to(avatars[0], {
                        transition: 'transform 300ms',
                        duration: 0.25,
                        translateX: '100px',
                        translateY: '-100px'
                    }, 2).to(avatars[1], {
                        transition: 'transform 300ms',
                        duration: 0.25,
                        translateX: '-80px',
                        translateY: '200px'
                    }, 2).to(avatars[2], {
                        transition: 'transform 300ms',
                        duration: 0.25,
                        translateX: '-50px',
                        translateY: '170px'
                    }, 2);
                }
            }, 2);

            tl.to(cta, {
                duration: 0.125,
                scale: 1.75,
                transformOrigin: 'center center',
                ease: "Expo.easeOut",
                onComplete: () => {

                }
            }, 2);

            tl.to(stars[0], {
                duration: 0.125,
                opacity: 1,
                translateX: 0,
                translateY: 0,
                ease: "Expo.easeOut",
            }, 3).to(stars[1], {
                duration: 0.125,
                opacity: 1,
                translateX: 0,
                translateY: 0,
                ease: "Expo.easeOut",
            }, 3).to(stars[2], {
                duration: 0.125,
                opacity: 1,
                translateX: 0,
                translateY: 0,
                ease: "Expo.easeOut",
            }, 3);

            const scrollAreaHeight = container.offsetHeight;
            let scrollAreaPosition = container.getBoundingClientRect().y * -1;

            window.addEventListener(
                "scroll",
                () => {
                    scrollAreaPosition = container.getBoundingClientRect().y * -1;
                    let calculatedProgress = scrollAreaPosition / (scrollAreaHeight * 0.5);
                    tl.progress(calculatedProgress);

                    if (calculatedProgress <= 0.25) {
                        tlAvatars.resume();
                    }
                },
                false
            );
        });
    }
}

export default new ScrollytellingCta;
