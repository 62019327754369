<script setup>
import { ref, computed } from "vue";

const props = defineProps({
  data: Object,
});

</script>

<template>
  <div class="columns advertiser-teaser">
    <div class="column is-3 is-background-light-gray">
      <div class="img-wrapper">
        <figure class="is-flex is-align-items-center is-justify-content-center">
          <a :href="`partnerprogramme/${data.advertiser_slug}`" target="_blank">
          <img :src="data.advertiser_logo" alt="" />
          </a>
        </figure>
      </div>
    </div>
    <div class="column  is-9">
      <div class="tag-row">
        <span v-for="tag in data.advertiser_tags" :key="tag" :class="{
            'tag': true,
          }">
          {{ tag }}
        </span>
        <span class="ml-auto">
          <span class="tag-row">
            <span v-for="tag in data.advertiser_countries" :key="tag" :class="{
            'tag': true,
          }">
              {{ tag }}
            </span>
          </span>
        </span>
      </div>
      <div class="mt-2"></div>
      <a :href="`partnerprogramme/${data.advertiser_slug}`" class="heading-3 is-no-link" target="_blank">{{ data.advertiser_name }}</a>
      <div class="advertiser-teaser-description mt-2" v-html="data.advertiser_description">
      </div>
      <div class="mt-2">
        <a class="is-bold" target="_blank" :href="`partnerprogramme/${data.advertiser_slug}`">mehr erfahren</a>
      </div>
    </div>
  </div>
</template>
