class Navbar {
    constructor() {
        this.body = document.querySelector('body');
        this.burgers = document.querySelectorAll('.js-navbar-burger');
        this.navbar = document.querySelector('.js-navbar');
    }

    init() {
        if (this.burgers.length > 0) {
            this.listenBurger();
        }

        if (this.navbar !== null) {
            this.observePosition();
        }
    }

    listenBurger() {
        const container = document.querySelector('.js-navbar');
        const sidebar = document.querySelector('.js-sidebar');
        const overlay = document.querySelector('.js-overlay');
        const logo = this.navbar.querySelector('.js-logo');
        const menu = this.navbar.querySelector('.js-navbar-menu');

        this.burgers.forEach((burger) => {
            burger.addEventListener('click', () => {
                burger.classList.toggle('is-active');
                sidebar.classList.toggle('is-active');
                overlay.classList.toggle('is-active');
                container.classList.toggle('has-pointer-events-none');

                if (overlay.classList.contains('is-active')) {
                    logo.classList.add('is-ghosted');
                    menu.classList.add('is-closed');
                    container.classList.add('has-pointer-events-none');
                }
            });
            overlay.addEventListener('click', () => {
                burger.classList.remove('is-active');
                sidebar.classList.remove('is-active');
                overlay.classList.remove('is-active');
                container.classList.remove('has-pointer-events-none');
            });
        });
    }

    observePosition() {
        const rect = this.body.getBoundingClientRect();

        // get elements
        const overlay = document.querySelector('.js-overlay');
        const logo = this.navbar.querySelector('.js-logo');
        const menu = this.navbar.querySelector('.js-navbar-menu');

        if (rect.top < 0) {
            logo.classList.add('is-ghosted');
            menu.classList.add('is-closed');
        } else {
            if (!overlay.classList.contains('is-active')) {
                logo.classList.remove('is-ghosted');
                menu.classList.remove('is-closed');
            }
        }

        requestAnimationFrame(this.observePosition.bind(this));
    }
}

export default new Navbar();
