import { gsap, ScrollTrigger } from "gsap";

class Parallax {
    constructor() {}

    initHeader(trigger, background, ...foregrounds) {
        this.trigger = trigger;
        this.background = background;
        this.foreground = foregrounds;

        if (
            this.validateSelector(this.trigger) &&
            this.validateSelector(this.background)
        ) {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: this.trigger,
                    start: "top top",
                    end: "bottom top",
                    scrub: true,
                },
            });

            gsap.utils.toArray(this.background).forEach((image) => {
                tl.to(
                    image,
                    {
                        y: image.offsetHeight * 0.15,
                        ease: "none",
                    },
                    0
                );
            });

            if (this.foreground) {
              this.foreground.forEach(selector => {
                if (this.validateSelector(selector)) {
                  let contentContainer = document.querySelector(selector)
                    tl.to(
                        contentContainer,
                        {
                            y: contentContainer.offsetHeight * 0.75,
                            ease: "none",
                        },
                        0
                    );
                }
              })
            }
        }
    }

    validateSelector(selector) {
        return !!selector && !!document.querySelector(selector);
    }
}

export default new Parallax();
