import {nextTick} from 'vue'

export function checkNeedShowMore(descriptionElement, showMore, ref) {
  if (descriptionElement.value) {
    const expandedHeight = descriptionElement.value.clientHeight; 
    showMore.value = false; 
    nextTick().then(() => {
      const initialHeight = descriptionElement.value.clientHeight; 
      
      ref.value = expandedHeight > initialHeight;
    });
  }
}

export function checkCommission(commissions, fixedKey, percentKey, returnDefaultSymbol) {
  let commissionData = commissions?.[fixedKey] || commissions?.[percentKey];
  let symbol = commissionData === commissions?.[percentKey] ? "%" : "€";

  if (commissionData && commissionData?.max !== 0) {
    const maxValue = formatNumber(commissionData.max);
    const minValue = formatNumber(commissionData.min);
    if (maxValue === minValue) {
      return `${maxValue} ${symbol}`;
    } else {
      return `bis ${maxValue} ${symbol}`;
    }
  }
  if (returnDefaultSymbol) return '--'
}

function formatNumber(num) {
  var rounded = num.toFixed(2);
  console.log('rounded', rounded)
  // Prüfe, ob die gerundete Zahl Dezimalstellen hat, die ungleich 0 sind
  if (parseFloat(rounded) === parseInt(rounded)) {
    // Keine relevanten Dezimalstellen, konvertiere zu Integer
    console.log('int', rounded)
    return parseInt(rounded);
  } else {
    // Es gibt relevante Dezimalstellen, gib die gerundete Zahl zurück
    console.log('float', rounded)
    return parseFloat(rounded).toFixed(2);
  }
}