import Swiper, { Pagination, Autoplay, Navigation, FreeMode } from 'swiper';

class Slider {
    constructor() {}

    init() {
        // init Swiper for Slider:  DELETE???
        const slider = new Swiper('.slider-js', {
            // configure Swiper to use modules
            modules: [Autoplay],
            loop: true,
            autoplay: true,
        });

        const sliderNavigation = new Swiper('.slider-navigation-js', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Navigation],
            autoplay: true,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
          },
        });
      
        const sliderRelatedArticles = new Swiper('.slider-related-articles-js', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay, Navigation],
            // autoplay: true,
            slidesPerView: 1.12,
            slidesOffsetBefore: 1,
            centeredSlides: true,
            spaceBetween: 10,
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    slidesOffsetBefore: 0,
                    spaceBetween: 15,
                    centeredSlides: false,
                }
            }
        });

        // init Swiper for Quote-Slider:
        const quoteSlider = new Swiper('.quote-slider-js', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay],
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            loop: true,
            autoplay: true,
            allowTouchMove: true,
        });

        // init Swiper for Customer-Quote-Slider:
        const customerQuoteSlider = new Swiper('.customer-quote-slider-js', {
            // configure Swiper to use modules
            modules: [Pagination, Autoplay],
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
              autoplay: {
                disableOnInteraction: false,
            },
            loop: true,
            autoplay: true,
            allowTouchMove: true,
            slidesPerView: 1,
            spaceBetween: 50
        });

        // init Swiper for Marquee:
        const marqueeOne = new Swiper('.marquee-one-js', {
            modules: [Autoplay],
            spaceBetween: 10,
            speed: 4000,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
            loop: true,
            slidesPerView: 2,
            breakpoints: {
              600: {
                slidesPerView: 3,
              }
            },
            allowTouchMove: true,
        });
        const marqueeTwo = new Swiper('.marquee-two-js', {
            modules: [Autoplay],
            spaceBetween: 10,
            speed: 4000,
            autoplay: {
                delay: 0, 
                reverseDirection: true,
                disableOnInteraction: false,
            },
            loop: true,
            slidesPerView: 2,
            breakpoints: {
              600: {
                slidesPerView: 3,
              }
            },
            allowTouchMove: true,
        });

        const singleMarquee = new Swiper('.single-marquee-js', {
          modules: [Autoplay],
          spaceBetween: 10,
          speed: 4000,
          autoplay: {
              delay: 100,
              disableOnInteraction: false,
          },
          loop: true,
          slidesPerView: 3,
          allowTouchMove: true,
          breakpoints: {
            769: {
              slidesPerView: 4,
            },
            960: {
              slidesPerView: 5,
            }
          },
      });
    }
}

export default new Slider();
