import {gsap} from "gsap";

class ScrollytellingBars {
    constructor() {
        this.container = document.querySelectorAll(".js-scrollytelling-bars");
    }

    init() {
        if (this.container.length > 0) {
            this.listenBars();
        }
    }

    listenBars() {
        // Fullscreen-Background will be reached after 50% of bar-container-height
        const durationFactor = 0.5;

        // loop through areas
        this.container.forEach((container) => {
            let bars = container.querySelectorAll('.bar');
            let content = container.querySelector('.scrollytelling-content');
            const tl = gsap.timeline({paused: true});

            // bars.forEach((bar) => {
            //     tl.to(bar, {
            //         duration: 0.5,
            //         translateX: 0,
            //         ease: "Expo.easeInOut"
            //     }, 0);
            // });

            tl.to(bars[0], {
                duration: 0.33,
                translateX: '-25vh',
                ease: "Expo.easeInOut"
            });

            tl.to(bars[1], {
                duration: 0.33,
                translateX: '-25vh',
                ease: "Expo.easeInOut"
            }, 0);

            tl.to(bars[2], {
                duration: 0.33,
                translateX: '-25vh',
                ease: "Expo.easeInOut"
            }, 0);

            tl.to(bars[3], {
                duration: 0.33,
                translateX: '-25vh',
                ease: "Expo.easeInOut"
            }, 0);

            tl.to(bars[0], {
                duration: 0.33,
                height: '100%',
                ease: "Expo.easeInOut"
            });

            tl.to(content, {
                duration: 0.33,
                translateY: 0,
                ease: "Expo.easeInOut"
            });

            const scrollAreaHeight = container.offsetHeight;
            let scrollAreaPosition = container.getBoundingClientRect().y * -1;

            window.addEventListener(
                "scroll",
                () => {
                    scrollAreaPosition = container.getBoundingClientRect().y * -1;
                    tl.progress(scrollAreaPosition / (scrollAreaHeight * 0.5));
                },
                false
            );

        });
    }
}

export default new ScrollytellingBars;
