<script setup>
import { ref, computed, watch, onMounted } from "vue";
import AdvertiserTeaser from "./AdvertiserTeaser.vue";
import CampaignTeaser from "./CampaignTeaser.vue";

const props = defineProps({
  tags: Array,
  countries: Array,
  preSelectedTag: String,
  preSelectedCountry: String,
  advertisers: Array,
  teaserType: String
});
const ITEMS_PER_PAGE = 5;
const page = ref(1)
const firstInteraction = ref(false);
const advertisers = ref(props.advertisers);
const components = {
  advertiser: AdvertiserTeaser,
  campaign: CampaignTeaser
}
const needsPaginateButton = ref(true)

const activeFilter = ref({ tags: [], countries: [] });

function filterChange(tag, type = "tags") {
  firstInteraction.value = true;
  if (tag === "all") {
    for (const key in activeFilter.value) {
      activeFilter.value[key] = [];
    }
  } else if (activeFilter.value[type].includes(tag)) {
    activeFilter.value[type] = activeFilter.value[type].filter(
      (t) => t !== tag
    );
  } else {
    activeFilter.value[type].push(tag);
  }
}

const activeAdvertisers = computed(() => {
  const filteredAdvertisers = filterAdvertisers();
  const paginatedAdvertisers = paginate(filteredAdvertisers)
  checkNeedsPaginateButton(filteredAdvertisers, paginatedAdvertisers)
  return paginatedAdvertisers
});

function checkNeedsPaginateButton(filteredAdvertisers, paginatedAdvertisers) {
   needsPaginateButton.value = filteredAdvertisers.length !== paginatedAdvertisers.length && props.teaserType === 'advertiser'
}

function filterAdvertisers() {
  
  const filteredAdvertisers = advertisers.value.filter((advertiser) => {
    const matchesTags =
      activeFilter.value.tags.length === 0 ||
      activeFilter.value.tags.some((tag) =>
        advertiser[`${props.teaserType}_tags`].includes(tag)
      );
    const matchesCountries =
      activeFilter.value.countries.length === 0 ||
      activeFilter.value.countries.some((country) =>
        advertiser[`${props.teaserType}_countries`].includes(country)
      );
      

    return matchesTags && matchesCountries;
  });
  return filteredAdvertisers
}

function paginate(advertiserArray) { 
return advertiserArray.slice(0, page.value * ITEMS_PER_PAGE)
}

function handlePagination() {
  firstInteraction.value = true;
  page.value ++
}

//fetch all data if someone intercats with component
/* 
watch(firstInteraction, () => {
  if (props.teaserType === 'advertiser') fetchData();
})
*/

function fetchData() { 
  fetch('/ajax/advertiser')
  .then(res => res.json())
  .then(data => {
    advertisers.value = data;
  })
} 

function setPreSelected() {
  if(props.preSelectedTag) {
    firstInteraction.value = true;
    activeFilter.value.tags = props.tags.filter((tag => tag.toLowerCase() === props.preSelectedTag.toLowerCase()))
  }
  if(props.preSelectedCountry) {
    firstInteraction.value = true;
    activeFilter.value.countries = props.countries.filter((country => country.toLowerCase() === props.preSelectedCountry.toLowerCase()))
  }
}

onMounted(() => {
  setPreSelected();
})

</script>

<template>
    <div class="columns is-gapless">
      <div class="column is-8">
        <div class="tag-row mb-5">
          <span :class="{
            'anker-link mr-3 is-background-dark is-color-white': true,
            'is-active': activeFilter.tags.includes('all'),
          }" @click="() => filterChange('all')">
            <!--    {{locale.allFilter}} -->
            Reset Filter
          </span>

          <span v-for="tag in tags" @click="() => filterChange(tag, 'tags')" :key="tag" :class="{
            'anker-link': true,
            'is-active': activeFilter.tags.includes(tag),
          }">
            {{ tag }}
          </span>
        </div>
      </div>
      <div class="column is-flex">
        <div class="tag-row margin-left-auto-tablet">
          <span v-for="country in countries" @click="() => filterChange(country, 'countries')" :key="country" :class="{
            'anker-link': true,
            'is-active':
              activeFilter.countries.includes(country),
          }">
            {{ country }}
          </span>
        </div>
      </div>
    </div>

    <div class="columns is-multiline advertiser-teaser-overview">
      <div v-for="advertiser in activeAdvertisers" class="column is-12">
        <component :is="components[teaserType]" :data="advertiser" />
      </div>
    </div>
    <div v-if="teaserType === 'advertiser' && needsPaginateButton" class="is-flex is-justify-content-center mt-5">
      <button class="button is-arrowless" @click="handlePagination()">
        Weitere Programme anzeigen
      </button>
    </div>
</template>
